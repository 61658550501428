/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  
  
  
$(function () {
	$('img.svg').each(function(){
	    var $img = jQuery(this);
	    var imgID = $img.attr('id');
	    var imgClass = $img.attr('class');
	    var imgURL = $img.attr('src');
	
	    $.get(imgURL, function(data) {
	        // Get the SVG tag, ignore the rest
	        
	                       
	        var $svg = $(data).find('svg');
			
			$svg.find('style').remove();
	
	        // Add replaced image's ID to the new SVG
	        if(typeof imgID !== 'undefined') {
	            $svg = $svg.attr('id', imgID);
	        }
	        // Add replaced image's classes to the new SVG
	        if(typeof imgClass !== 'undefined') {
	            $svg = $svg.attr('class', imgClass+' replaced-svg');
	        }
	
	        // Remove any invalid XML tags as per http://validator.w3.org
	        $svg = $svg.removeAttr('xmlns:a');
	       
	
	        // Replace image with new SVG
	        $img.replaceWith($svg);
	
	    }, 'xml');
	}); // img.svg
	
	
	
    $('.flexslider:not(.slider-produkt)').flexslider({
        controlNav: "thumbnails",
        directionNav: false,
    });
    
    
    if(typeof onStartReel !== 'undefined'){
    
	    $('.slider-produkt').flexslider({
	        controlNav: "thumbnails",
	        directionNav: false,
	        slideshow: false,
	        touch: false,
	        start: onStartReel,
	    });
    
    }
    
    
	$('#newsletter').submit(function (event) {
		event.preventDefault();
		
		var data = $( this ).serialize();
		
		$.post( v.ajaxurl, data, function (data) {
			var info = $('#newsletter_modal');
			if( data.status ) {
				info.find('.title').text('Dziękujemy!');
				info.find('.text').text('Pomyślnie zapisałeś się do newslettera.');
				info.modal('show');
			} else {
				info.find('.title').text('Błąd');
				info.find('.text').text('Wystąpił błąd, prosimy spróbować poźniej');
				info.modal('show');
			}
		});
		
	});
	
})

  

})(jQuery);




var TagFiltersRealization = ( function ($)
{
	var _private = {
		
		activeFilter: function ()
		{
			
		},
		
		
		checkHash: function ()
		{
			var hash = window.location.hash;
			
			if( hash ) {				
				filter = hash.substr( 1 );
				_private.setFilter( filter );
				_private.eClick( filter );
			}
		},
		
		setFilter: function ( filter )
		{
			var items = $( '.realization-items .item' );
			
			items.removeClass('item-hide');
			
			if( filter === '*' )
				return ;
			
			items.each(function () {
				
				$this = $( this );
				
				if( !$this.hasClass( filter ) )
				{
					$this.addClass('item-hide');
				}
			});
		},
		
		
		click: function ( event )
		{
			event.preventDefault();
			
			var $this = $( this );
			var filter = $this.data('filter');
			
			
			$( '.realization-tags a' ).parent().removeClass('active');
			$( this ).parent().addClass('active');
			
			_private.setFilter( filter );
			
		},
		
		eClick: function ( filter )
		{
			$( '.realization-tags a' ).each(function () {
				var $this = $( this );
				var _filter = $this.data('filter');
				
				if( _filter == filter ) {
					$( '.realization-tags a' ).parent().removeClass('active');
					$this.parent().addClass('active');
					return;
				}
			});			
		},
		
		
		ready: function () 
		{
			$( '.realization-tags a' ).click( _private.click );
			
			_private.checkHash();
		}
	};
	
	
	var _public = {
		init : function () 
		{
			$( document ).ready( _private.ready );
		}
	};
	
	return _public;
}(jQuery));

TagFiltersRealization.init();








